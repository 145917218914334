



























































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class WSwitch extends Vue {
	@Prop() value!: boolean;
	@Prop({ default: 'lg' }) size!: 'sm' | 'lg' | '';
	@Prop() title!: string;
	@Prop({ default: '' }) titleClass!: string;
	@Prop() description!: string;
	@Prop() icon!: string;
	@Prop({ default: '' }) iconClass!: string;
	@Prop({ default: '' }) switchClass!: string;
	@Prop({ default: false, type: Boolean }) disabled!: boolean;

  id = Math.random().toString(36).substring(2, 10);

	isIcon(str: string): boolean {
		return this.icon === str;
	}

	set _value(val: boolean) {
    if (val === this.value) return;
    this.$emit('input', val);
	}

	get _value(): boolean {
		return this.value;
	}
}
