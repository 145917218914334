








































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import WCards from '@/components/listlayout/WCards.vue';
import WTable from '@/components/listlayout/WTable.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import WPagination from '@/components/WPagination.vue';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { BookingListItem, ListOptions } from '@/models/Booking';
import { SelectOption } from '@/models/common';

@Component({
	components: { HeaderLayout, WCards, WTable, WListOptions, WPagination }
})
export default class ListWithHeaderLayout extends Vue {
	@Prop() title!: string;
	@Prop() subtitle?: string;
	@Prop() items!: BookingListItem[];

	@Prop() fields!: any[];
	@Prop() bread!: any[];

	@Prop({ default: 'bg-white text-secondary' }) thColorClass?: string;
	@Prop({ default: null }) newButton!: string | { name: string; query?: any };

	@Prop({ default: false, type: Boolean }) serverSide!: boolean;
	@Prop() serverSideOptions!: {
		entryCount: number;
		mode: 'cards' | 'table';
		searchTerm: string;
		currentPage: number;
		totalRows: number;
		sortBy: string;
		sortDesc: boolean;
	};

	@Prop() dateOptions!: SelectOption[];
	@PropSync('selectedDate') selectedDateFilter!: string;

	filteredItems: BookingListItem[] = [];

	options: ListOptions = {
		mode: 'cards',
		entryCount: 10,
		searchTerm: ''
	};

	@Watch('items')
	itemsChanged(): void {
		this.filteredItems = this.items;
		if (!this.serverSide && this.options.searchTerm !== '') {
			this.updateCardItems(this.options.searchTerm);
		}
	}

	@Watch('options.searchTerm')
	updateCardItems(n: string): void {
		if (n === '' || !n) {
			this.filteredItems = [...this.items];
		} else {
			this.filteredItems = this.items.filter((item) => {
				return (
					item.topic.toLowerCase().includes(n.toLowerCase()) ||
					item.date.toLocaleDateString().includes(n) ||
					item.pin?.moderator.includes(n) ||
					item.pin?.participant.includes(n)
				);
			});
		}
	}

	currentPage = 1;

	get isCards(): boolean {
		return (
			(this.serverSide ? this.serverSideOptions.mode : this.options.mode) ===
			'cards'
		);
	}

	get perPage(): number {
		return this.serverSide
			? this.serverSideOptions.entryCount
			: this.options.entryCount;
	}

	set perPage(count: number) {
		if (this.serverSide) {
			this.serverSideOptions.entryCount = count;
		} else {
			this.options.entryCount = count;
		}
	}

	get totalPages(): number {
		return Math.ceil(this.totalRows / this.perPage);
	}

	get totalRows(): number {
		return this.serverSide
			? this.serverSideOptions.totalRows
			: this.filteredItems.length;
	}

	get showPagination(): boolean {
		return this.perPage <= this.totalRows;
	}
}
