




















































































































































































































































































































































































































































import {Component, Watch} from 'vue-property-decorator';
import AuthenticationService from '@/service/AuthenticationService';
import {Validations} from 'vuelidate-property-decorators';
import {requiredIf} from 'vuelidate/lib/validators';
import LoginPasswordResetModal from './LoginPasswordResetModal.vue';
import LoginModeratorMask from './LoginModeratorMask.vue';
import LoginParticipantMask from './LoginParticipantMask.vue';
import Base from './Base';
import LoginOffer from "@/views/LoginOffer.vue";

@Component({
  components: {
    LoginOffer,
    LoginPasswordResetModal,
    LoginModeratorMask,
    LoginParticipantMask
  }
})
export default class Login extends Base {
  form = {
    username: '',
    password: '',
    name: '',
    pin: ''
  };

  error = '';

  passwordReset = false;

  loginMode = 'participant' as 'moderator' | 'participant';

  @Validations()
  validations = {
    form: {
      username: {required: requiredIf(() => this.loginMode === 'moderator')},
      password: {required: requiredIf(() => this.loginMode === 'moderator')},
      name: {required: requiredIf(() => this.loginMode === 'participant')},
      pin: {required: requiredIf(() => this.loginMode === 'participant')}
    }
  };

  @Watch('theme')
  themeChanged(): void {
    this.setLoginMode();
  }

  mounted(): void {
    this.checkSessionAndQueryParams();
    this.setLoginMode();
  }

  checkSessionAndQueryParams(){
    if (this.$store.getters.username && this.$route.query.pin == undefined) {
      AuthenticationService.fetchMyAccount().then(()=>{
			console.log('Redirect to /');
      this.$router.push("/");
		});
    } else if(this.$route.query.pin != undefined){
      console.log("Ignoring authentication information. Pin Url parameter is given");
    }
  }

  setLoginMode(): void {
    if (this.$route.query.pin != undefined || this.$route.query.name != undefined) {
      this.loginMode = 'participant';
    } else if (localStorage.loginMode) this.loginMode = localStorage.loginMode;
    if (this.loginMode == 'participant') {
      let pin = this.$route.query.pin;
      let name = this.$route.query.name;
      this.form.pin = pin ? pin as string : '';
      this.form.name = name ? name as string : '';
    }
  }

  login(): void {
    if (this.form.username && this.form.password) {
      console.log("Do login with username", this.form.username);
      AuthenticationService.login(this.form.username, this.form.password)
          .then((account) => {
            console.log("Login succeeded", account);
            this.$store.commit('setLayout', '');
            if (account && account.role !== 'USER') {
              console.log('Go to admin console', '/admin/console/running');
              this.$router.push('/admin/console/running');
            } else {
              console.log('Go to start page', '/');
              this.$router.push('/');
            }
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response.status === 403) {
              this.$bvModal.show('password-change');
              return;
            }
            if (err.response.status === 500) {
              this.error =
                  this.t('common.serverConnectionError');
            } else {
              this.error = this.t('login.errors.invalidUsernameOrPassword');
              localStorage.removeItem('access_token');
            }
          });
    } else {
      this.$v.form.username?.$touch();
      this.$v.form.password?.$touch();
      this.error = this.t('login.errors.invalidUsernameOrPassword');
    }
  }

  async start(): Promise<void> {
    if (this.form.name && this.form.pin) {
      this.form.pin = this.form.pin.replace(/[^0-9]/g, '');

      const result = await AuthenticationService.checkDCPSessionExists(
          this.form.name,
          this.form.pin,
          this.theme.weblink.dataPinCheckUrl
      );
      switch (result.result) {
        case 'false':
          this.error = this.t('login.errors.noPresentation');
          return;
        case 'falseName':
          this.error = this.t('login.errors.falseName');
          return;
        case 'maxLimit':
          this.error =
              this.t('login.errors.falseName');
          return;
        case 'notStarted':
          this.error = this.t('login.errors.notStarted');
          return;
        case 'sessionLocked':
          this.error = this.t('login.errors.sessionLocked');
          return;
        case 'true': {
          console.log("PIN is ok")
          let endUrl = '';
          if (this.$route.query.endUrl) {
            endUrl = '&endUrl=' + this.$route.query.endUrl;
          } else {
            endUrl = this.theme && this.theme.weblink?.endUrl
                ? '&endUrl=' + this.theme.weblink.endUrl
                : '';
          }
          const dataUrl =
              this.theme && this.theme.weblink?.dataDcpUrl
                  ? this.theme.weblink.dataDcpUrl
                  : 'https://www.csnstart.de';

          let paramObject: any = {};
          Object.entries(this.$route.query).forEach(value => {
            if (value[0] === 'endUrl') return;
            if (value[0] !== 'pin') paramObject[value[0]] = value[1];
          })
          paramObject.name = this.form.name;
          const params = `?${new URLSearchParams(paramObject).toString()}`;
          const dcpUrl = `${dataUrl}/conference/dcp/${this.form.pin}${params}${endUrl}`
          console.log("open dcp url", dcpUrl)

          window.location.href = dcpUrl;
          this.error = '';
          return;
        }
      }
    } else {
      this.error = this.t('login.errors.insertNameAndPin');
    }
  }

  toggleLogin(mode: 'participant' | 'moderator'): void {
    this.$v.$reset();
    this.error = '';
    this.loginMode = mode;
    localStorage.loginMode = mode;
  }

  getContactLink(): string {
    if (this.csn || !this.theme.textbox || !this.theme.textbox.testUrl)
      return `https://www.csn-gmbh.de/${this.en ? 'en/' : ''}kontakt`;
    return this.theme.textbox.testUrl;
  }

  changeLang(newLang: 'en' | 'de'): void {
    this.$i18n.locale = newLang;
    localStorage.setItem('language', newLang);
  }

  get hasSubdomain(): boolean {
    return this.theme.subdomain !== 'default';
  }

  get backgroundClass(): string {
    return this.hasSubdomain
        ? 'bg-white'
        : 'bg-header text-white'
  }
}
