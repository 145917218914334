




















































































































































































































































































































































































































































































































































import {Component} from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import SidebarLayout from '@/layouts/nested/SidebarLayout.vue';
import WSwitch from '@/components/WSwitch.vue';
import AdminSection from './AdminSection.vue';
import {Account, PasswordPolicy, Permission, PermissionName} from '@/models/Account';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators';
import ContactsService from '@/service/ContactsService';
import Base from '../Base';
import AccountsService from '@/service/AccountsService';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WCostCenter from '@/components/WCostCenter.vue';
import WCountrySelect from '@/components/WCountrySelect.vue';
import CustomersService from '@/service/CustomersService';
import {Customer} from '@/models/Customer';
import {validateEmail} from '@/utils/validators';
import {getLanguages} from "@/utils/languages";
import {AdminSectionField} from "@/models/AdminForms";
import {managementPermList, productPermList} from "@/utils/permissionLists";

/**
 * Some documented component
 *
 * @component
 */
@Component({
  components: {
    HeaderLayout,
    SidebarLayout,
    WSwitch,
    AdminSection,
    WHeaderBtn,
    WCostCenter,
    WCountrySelect
  }
})
export default class UsersItem extends Base {
  account: Account = {
    role: 'USER',
    timezone: '',
    username: '',
    dateCreated: 0,
    id: 0,
    language: 'DE',
    permissions: [],
    contact: {
      lastName: '',
      email: '',
      firstName: '',
      phone: '',
      city: '',
      country: '',
      street: '',
      zip: '',
      company: '',
      salutation: 'MR',
      title: '',
      phone2: ''
    },
    costCenter: '',
    passwordPlain: null,
    active: true,
    dateLastLogin: Date.now(),
    deactivationReason: 'NONE',
    failedLoginCounter: 0,
    participantLimit: 0,
    passwordPolicy: 'NONE',
    timeLimit: -1,
    customerId: 0,
    bookingPreset: {
      dialOutCallRetryCount: 2,
      dialOutCallRetryDelay: 60
    }
  };

  productPermList = productPermList;
  managementPermList = managementPermList;

  userAccount: AdminSectionField[] = [];
  personal: AdminSectionField[] = [];
  contact: AdminSectionField[] = [];
  settings: AdminSectionField[] = [];

  languages = getLanguages(this);
  timezones!: { value: string; text: string }[];
  salutations = [
    {text: this.t('common.mr'), value: 'MR'},
    {text: this.t('common.mrs'), value: 'MRS'},
    {text: this.t('common.diverse'), value: 'OTHER'}
  ];

  newCostCenter = '';

  costCenters = [{text: this.t('conference.noCostCenter'), value: '', undeletable: true}];
  passwordPolicies: { text: string; value: PasswordPolicy }[] = this.dev
      ? [
        {text: this.t('admin.passwordPolicyChangeAfterLogin'), value: 'CHANGE_BY_FIRST_LOGIN'},
        {
          text: this.$t('admin.passwordPolicyChangeAfterLoginAndNDays', {n: 30}) as string,
          value: 'CHANGE_BY_FIRST_LOGIN_AND_30_DAYS'
        },
        {
          text: this.$t('admin.passwordPolicyChangeAfterLoginAndNDays', {n: 60}) as string,
          value: 'CHANGE_BY_FIRST_LOGIN_AND_60_DAYS'
        },
        {
          text: this.$t('admin.passwordPolicyChangeAfterLoginAndNDays', {n: 90}) as string,
          value: 'CHANGE_BY_FIRST_LOGIN_AND_90_DAYS'
        },
        {
          text: this.$t('admin.passwordPolicyChangeAfterLoginAndNDays', {n: 180}) as string,
          value: 'CHANGE_BY_FIRST_LOGIN_AND_180_DAYS'
        },
        {
          text: this.$t('admin.passwordPolicyChangeAfterLoginAndNDays', {n: 360}) as string,
          value: 'CHANGE_BY_FIRST_LOGIN_AND_360_DAYS'
        },
        {text: this.t('admin.none'), value: 'NONE'}
      ]
      : [
        {text: this.t('admin.passwordPolicyChangeAfterLogin'), value: 'CHANGE_BY_FIRST_LOGIN'},
        {text: this.t('admin.none'), value: 'NONE'}
      ];

  editActive = false;
  loadingPhone1 = false;
  loadingPhone2 = false;

  info = '';
  userActive = false;
  userActiveHover = false;

  permissionNames: PermissionName[] = [];

  limit = false;
  timeLimitStr = '';

  participantLimit = -1;
  participantLimitNum = 0;

  customer: Customer | null = null;
  customerPermissionNames: PermissionName[] = [];

  oldUsername = '';

  get userAccountValid(): boolean {
    return (
        (this.$v.account.$anyDirty &&
            !this.$v.account.username?.$invalid &&
            !this.$v.account.passwordPlain?.$invalid) ||
        false
    );
  }

  get personalValid(): boolean {
    return (
        (this.$v.account.$anyDirty &&
            !this.$v.account.contact?.salutation?.$invalid &&
            !this.$v.account.contact?.firstName?.$invalid &&
            !this.$v.account.contact?.lastName?.$invalid) ||
        false
    );
  }

  get contactValid(): boolean {
    return (
        (this.$v.account.$anyDirty &&
            !this.$v.account.contact?.phone?.$invalid &&
            !this.$v.account.contact?.phone?.$invalid) ||
        false
    );
  }

  get settingsValid(): boolean {
    return (
        (this.$v.account.$anyDirty &&
            !this.$v.account.language?.$invalid &&
            !this.$v.account.timezone?.$invalid) ||
        false
    );
  }

  get dateCreated(): Date {
    return new Date(this.account.dateCreated);
  }

  get lastLogin(): Date {
    return new Date(this.account.dateLastLogin);
  }

  @Validations()
  validations = {
    account: {
      contact: {
        salutation: {required},
        firstName: {required},
        lastName: {required},
        phone: {
          required,
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('account.contact.phone')
        },
        phone2: {
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('account.contact.phone2')
        },
        email: {email: validateEmail},
        street: {required},
        zip: {required},
        city: {required},
        country: {required},
        title: {},
        company: {}
      },
      language: {required},
      timezone: {required},
      costCenter: {},
      username: {required},
      passwordPlain: {},
      passwordPolicy: {},
      timeLimit: {}
    }
  };

  mounted(): void {
    this.getAccount();
    this.setTimezones();
  }

  toggleStatus(): void {
    if (this.account.active) {
      AccountsService.deactivateAccount(this.account.username)
          .then((account) => this.setAccount(account))
          .catch(() => this.toast(this.t('admin.errorOnDeactivation'), 'danger'));
    } else {
      AccountsService.activateAccount(this.account.username)
          .then((account) => this.setAccount(account))
          .catch(() => this.toast(this.t('admin.errorOnActivation'), 'danger'));
    }
    this.userActiveHover = false;
  }

  getAccount(): void {
    const {id} = this.$route.params;
    if (id) {
      AccountsService.getAccount(id)
          .then((account) => {
            this.setAccount(account);
            this.oldUsername = account.username;
            if (this.isSAdmin() || this.isAdmin()) {
              AccountsService.getInfo(account.username).then(
                  (info) => (this.info = info)
              );
            }
            if (account.customerShortDTO) {
              CustomersService.getCustomer('' + account.customerShortDTO.id).then(
                  (customer) => {
                    console.log(account.permissions);
                    this.customer = customer;
                    this.account.customerId = this.customer.id;
                    this.customerPermissionNames = [];
                    if (!this.account.permissions) {
                      this.account.permissions = [];
                    }
                    this.permissionNames = [];
                    if (this.customer?.permissions) {
                      this.customerPermissionNames = this.customer.permissions.map(
                          (p) => p.name
                      );
                      // this.customerPermissionNames.forEach(permission => {
                      //   (this.account.permissions as Permission[]).push({name: permission, additional: ''})
                      // })
                      this.permissionNames = this.account.permissions.map(
                          (p) => (p as Permission).name
                      );
                    }
                  }
              );
            }
          })
          .catch(this.showNetworkError);
    }
  }

  submitAfterTouch(submitFtn: () => void, path: string): void {
    const validate = this.$v.account[path];
    if (validate) {
      // first ftn call: not pending,
      // then touch and try until phone validation is done
      if (!validate.$pending) validate.$touch();
      setTimeout(() => {
        if (validate.$pending) {
          console.log('pending, try again');
          this.submitAfterTouch(submitFtn, path);
        } else if (!validate.$anyError) {
          console.log('phone validation done, submit');
          submitFtn();
        }
      }, 250);
    } else {
      console.log('ein fehler ist aufgetreten, kein form gefunden');
    }
  }

  save(): void {
    this.editActive = false;
    this.account.timeLimit = this.limit
        ? new Date(this.timeLimitStr).getTime()
        : -1;
    this.account.participantLimit =
        this.participantLimit < 0 ? -1 : +this.participantLimitNum;
    if (this.account.passwordPlain === '') this.account.passwordPlain = null;
    this.updateAccount();
  }

  saveInfo(): void {
    if (this.info) {
      AccountsService.updateInfo(this.account.username, this.info).then(() =>
          this.toast(this.t('admin.descriptionChangedSuccessfully'), 'success')
      );
    }
  }

  togglePermission(permission: PermissionName): void {

    console.log('togglePermission', permission);

    const permissions = this.account.permissions.map(
        (p) => (p as Permission).name
    );
    if (permissions.includes(permission)) {
      this.account.permissions = (
          this.account.permissions as Permission[]
      ).filter((p: Permission) => p.name !== permission);
    } else {
      (this.account.permissions as Permission[]).push({
        name: permission,
        additional: ''
      });
    }
    AccountsService.updatePermissions(
        this.account.username,
        this.account.permissions as Permission[]
    ).then(() => {
      this.toast(this.t('admin.productChanged'), 'success');
      if (this.account.permissions)
        this.permissionNames = this.account.permissions.map(
            (permission) => (permission as Permission).name
        );
    });
  }

  private updateAccount() {
    const acc = this.account;
    acc.permissions = acc.permissions.map((p) => (p as Permission).name);
    AccountsService.updateAccount(acc, this.oldUsername)
        .then((account) => {
          this.setAccount(account);

          this.toast(this.t('profile.profileUpdated'), 'success');
          this.$v.account.$reset();
          if (this.oldUsername && this.oldUsername !== account.username) {
            this.$router.push('/admin/users/' + account.username);
          }
        })
        .catch(err => {
          if (this.$v.account.passwordPlain && this.$v.account.passwordPlain.$dirty) {
            this.toast(this.t('admin.passwordNotFollowingGuidelines'), 'danger');
          } else if (err.response.data._embedded.errors[0].message.includes('Dieser Benutzername ist bereits registriert.')) {
            this.toast(this.t('admin.usernameAlreadyTaken'), 'danger');
          }
          else {
            this.toast(
                this.t('common.anErrorOccurredReloadPage'),
                'danger'
            );
          }
          console.error(err.response.data._embedded.errors[0].message);
          this.$v.account.$reset();
        });
  }

  cancel(): void {
    this.editActive = false;
    this.$v.account.$reset();
    this.getAccount();
  }

  private setAccount(account: Account): void {
    this.account = {
      ...this.account,
      ...account
    };
    const salutation = this.salutations.find(
        (s) => s.value === this.account.contact.salutation
    )?.text;
    this.userAccount = [
      {text: this.t('common.username') + '*', value: this.account.username},
      {text: this.t('common.password'), value: '******'},
      {
        text: this.t('admin.passwordPolicy'), value: this.account.passwordPolicy === 'CHANGE_BY_FIRST_LOGIN'
            ? this.t('admin.passwordPolicyChangeAfterLogin')
            : this.t('admin.none')
      },
      {
        text: this.t('admin.timeLimit'), value: this.account.timeLimit && this.account.timeLimit !== -1
            ? this.t('admin.to') + ' ' + new Date(this.account.timeLimit).toLocaleDateString()
            : this.t('admin.none')
      }
    ]

    this.personal = [
      {text: this.t('common.salutation') + '*', value: salutation || ''},
      {text: this.t('common.title'), value: this.account.contact.title || ''},
      {text: this.t('common.firstname') + '*', value: this.account.contact.firstName || ''},
      {text: this.t('common.lastname') + '*', value: this.account.contact.lastName || ''},
      {text: this.t('common.company'), value: this.account.contact.company || ''},
      {text: '', value: ''},
      {text: this.t('common.streetAndNumber'), value: this.account.contact?.street || ''},
      {text: this.t('common.zipCode'), value: this.account.contact?.zip || ''},
      {text: this.t('common.city'), value: this.account.contact?.city || ''},
      {
        text: this.t('common.country'), value: AccountsService.getCountry(
            this.account.contact.country,
            this.user.language
        )
      },
    ]

    this.contact = [
      {text: this.t('common.phone') + '*', value: this.account.contact.phone},
      {text: this.t('common.phoneMobile'), value: this.account.contact.phone2 || ''},
      {text: this.t('common.email') + '*', value: this.account.contact.email || ''},
    ]

    this.settings = [
      {text: this.t('common.language') + '*', value: this.account.language},
      {text: this.t('common.timezone') + '*', value: this.account.timezone},
      {text: this.t('common.costCenterPreset'), value: this.account.costCenter?.split('|')[0] || ''},
      {
        text: this.t('admin.participantLimit') + '*', value: this.account.participantLimit === -1
            ? this.t('admin.none')
            : this.account.participantLimit + '' || ''
      },
    ]

    this.participantLimit = this.account.participantLimit > -1 ? 0 : -1;
    this.participantLimitNum =
        this.account.participantLimit === -1 ? 0 : this.account.participantLimit;

    if (this.account.permissions)
      this.permissionNames = this.account.permissions.map(
          (permission) => (permission as Permission).name
      );
  }

  setTimezones(): void {
    const tzList: { [key: string]: string } = {
      'Etc/GMT+12': '(GMT-12:00) International Date Line West',
      'Pacific/Midway': '(GMT-11:00) Midway Island, Samoa',
      'Pacific/Honolulu': '(GMT-10:00) Hawaii',
      'US/Alaska': '(GMT-09:00) Alaska',
      'America/Los_Angeles': '(GMT-08:00) Pacific Time (US & Canada)',
      'US/Arizona': '(GMT-07:00) Arizona',
      'America/Managua': '(GMT-06:00) Central America',
      'US/Central': '(GMT-06:00) Central Time (US & Canada)',
      'America/Bogota': '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
      'US/Eastern': '(GMT-05:00) Eastern Time (US & Canada)',
      'Canada/Atlantic': '(GMT-04:00) Atlantic Time (Canada)',
      'America/Argentina/Buenos_Aires': '(GMT-03:00) Buenos Aires, Georgetown',
      'America/Noronha': '(GMT-02:00) Mid-Atlantic',
      'Atlantic/Azores': '(GMT-01:00) Azores',
      'Etc/Greenwich':
          '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
      'Europe/Berlin':
          '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      'Europe/Helsinki':
          '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      'Europe/Moscow': '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
      'Asia/Tehran': '(GMT+03:30) Tehran',
      'Asia/Yerevan': '(GMT+04:00) Yerevan',
      'Asia/Kabul': '(GMT+04:30) Kabul',
      'Asia/Yekaterinburg': '(GMT+05:00) Yekaterinburg',
      'Asia/Karachi': '(GMT+05:00) Islamabad, Karachi, Tashkent',
      'Asia/Calcutta': '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      'Asia/Katmandu': '(GMT+05:45) Kathmandu',
      'Asia/Dhaka': '(GMT+06:00) Astana, Dhaka',
      'Asia/Rangoon': '(GMT+06:30) Yangon (Rangoon)',
      'Asia/Bangkok': '(GMT+07:00) Bangkok, Hanoi, Jakarta',
      'Asia/Hong_Kong': '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
      'Asia/Seoul': '(GMT+09:00) Seoul',
      'Australia/Adelaide': '(GMT+09:30) Adelaide',
      'Australia/Canberra': '(GMT+10:00) Canberra, Melbourne, Sydney',
      'Asia/Magadan': '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
      'Pacific/Auckland': '(GMT+12:00) Auckland, Wellington',
      'Pacific/Tongatapu': "(GMT+13:00) Nuku'alofa"
    };

    this.timezones = Object.keys(tzList).map((key: string) => ({
      value: key,
      text: tzList[key]
    }));
  }

  validateState(name: string): boolean | null {
    let validate: any = this.$v.account;
    const nameArr = name.split('.');
    if (nameArr.length === 1) validate = validate[name];
    else nameArr.forEach((n) => (validate = validate[n]));
    return validate.$dirty && validate.$error ? false : null;
  }

  phoneFormatValidator(
      phoneStr: string
  ): (value: string) => boolean | Promise<boolean> {
    // return Vuelidate validate function that uses phoneStr
    // phoneStr has an 'object.attribute.attribute' structure
    return (value) => {
      const phoneArr: any[] = phoneStr.split('.');
      let phoneForm: any = this.$v; // form
      phoneArr.forEach((p) => (phoneForm = phoneForm[p]));
      if (
          (!phoneForm.required && value === '') ||
          (!phoneForm.required && !value)
      )
        return true;
      if (phoneForm?.$dirty && this.account) {
        // validate
        return ContactsService.validatePhoneNumbers([value])
            .then((validatedNumbers) => {
              const validNumber = validatedNumbers[0];
              const phoneIsValid = validNumber.validNumber;
              if (phoneIsValid) {
                // replace with international
                switch (phoneArr.length) {
                  case 2:
                    (this.account as any)[phoneArr[1]] =
                        validNumber.international;
                    break;
                  case 3:
                    (this.account as any)[phoneArr[1]][phoneArr[2]] =
                        validNumber.international;
                    break;
                }
              }
              return phoneIsValid;
            })
            .catch(() => false);
      }
      return false;
    };
  }
}
