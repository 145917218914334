

























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class WHeaderBtn extends Vue {
	@Prop() to!: string | { name: string };
	@Prop() title!: string;
	@Prop() text!: string;
	@Prop() icon!: string | string[];
	@Prop() textClass!: string;
	@Prop() variant!: string;
	@Prop({ type: Boolean, default: false }) disabled!: boolean;

	isIcon(str: string): boolean {
		if (typeof this.icon === 'string') return this.icon === str;
		else return this.icon[1] === str;
	}
}
