import AuthenticationService, {
	TokenPayload
} from '@/service/AuthenticationService';
import axios from 'axios';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { icons } from '@/utils/WebcallIcons';

import Vue from 'vue';
import Vuelidate from 'vuelidate';

import VuePapaParse from 'vue-papa-parse';

import Countries from 'i18n-iso-countries';
import CountriesEN from 'i18n-iso-countries/langs/en.json';
import CountriesDE from 'i18n-iso-countries/langs/de.json';

import Quill from 'quill';
import PlainClipboard, { CustomLink } from '@/utils/quill';
import jwt_decode from 'jwt-decode';

export function initDependencies(): void {
	// Quill Editor
	Quill.register({ 'modules/clipboard': PlainClipboard }, true);
	Quill.register({ 'formats/link': CustomLink }, true);

	// Vuelidate Form Validation
	Vue.use(Vuelidate);

	// CSV Export/Import
	Vue.use(VuePapaParse);

	// i18n Countries
	Countries.registerLocale(CountriesEN);
	Countries.registerLocale(CountriesDE);
}

export function initIcons(): void {
	Vue.use(BootstrapVue);
	Vue.use(BootstrapVueIcons);

	icons.forEach((icon) => {
		Vue.component(icon.name, icon.icon);
	});
}

export function initBackendUrl(): void {
	let backendUrl = process.env.VUE_APP_BACKEND_URL;
	if (backendUrl === undefined) {
		console.log(
			"No environment variable 'VUE_APP_BACKEND_URL' found. Use 'http://localhost:8080"
		);
		backendUrl = 'http://localhost:8080';
	}
	console.log('Backend-URL', backendUrl);
	axios.defaults.baseURL = backendUrl;
	axios.defaults.headers.common['X-Client-Source'] = 'client';

}

export function initSessionHandling(
	router: VueRouter,
	store: Store<any>
): void {
	console.log('Init session handling');

	store.dispatch('loadAuthentication');
	checkRefreshToken(router, store);

	router.beforeEach((to, from, next) => {
		store.commit('setLastActionTimestamp');
		if (to.path === '/login' || store.getters.username) {
			if (
				to.path === '/' &&
				store.getters.account &&
				store.getters.account.role !== 'USER'
			) {
				next('/admin/console/running');
				return;
			}
			next();
			return;
		}
		next('/login');
	});

	if (store.getters.username) {
		console.log('Authentication information found');
		AuthenticationService.fetchMyAccount().then();
	} else {
		console.log('No authentication information found');
		//router.push("/login") TODO: Ask lennard why this push is needed
	}

	// Listen on storages changes from different browser tab
	window.onstorage = (e: StorageEvent) => {
		console.log('Authentication in localstorage changed', e);
		if (e.key === 'authentication') {
			store.dispatch('loadAuthentication');
			if (store.getters.username) {
				AuthenticationService.fetchMyAccount().then(() => {
					if (router.currentRoute.path === '/login') {
						console.log('Go to', '/');
						router.push('/');
					}
				});
			} else {
				console.log('Go to', '/login');
				router.push('/login');
			}
		}
	};

	setInterval(() => {
		console.log('Check token');
		if (store.getters.username) {
			const timeoutLastAction = 1000 * 60 * 60 * 2; // 2 hours
			console.log(
				'Check timeout, [ms] since last action:',
				Date.now() - store.getters.lastActionTime
			);
			if (Date.now() - store.getters.lastActionTime > timeoutLastAction) {
				console.log('Timed out');
				store.commit('clearAuthentication');
				router.push('/login');
				return;
			}
			checkRefreshToken(router, store);
		}
	}, 10000);
}

function checkRefreshToken(router: VueRouter, store: Store<any>): void {
	if (store.getters.username) {
		// Refresh the tokens 5 minutes before token expires
		const refreshLimitExpiration = +store.getters.expiration - 1000 * 60 * 5;
		console.log('Refresh time:', new Date(refreshLimitExpiration));
		if (Date.now() > refreshLimitExpiration) {
			console.log('Refresh now');
			AuthenticationService.refreshToken(store.getters.refreshToken)
				.then((tokens) => {
					console.log('login data received: ', tokens);
					const payload: TokenPayload = jwt_decode<TokenPayload>(
						tokens.accessToken
					);
					store.commit('setAuthentication', {
						accessToken: tokens.accessToken,
						refreshToken: tokens.refreshToken,
						username: payload.sub,
						expiration: payload.exp * 1000
					});
				})
				.catch((err) => {
					console.error('Error getting new token', err);
					store.commit('clearAuthentication');
					router.push('/login');
				});
		}
	}
}
