






















































import { DropdownItem } from '@/models/Nav';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class WDropdownItem extends Vue {
	@Prop() ddItem!: DropdownItem;
	@Prop() active!: boolean;

	isIcon(str: string): boolean {
		if (typeof this.ddItem.icon === 'string') return this.ddItem.icon === str;
		else return this.ddItem.icon[1] === str;
	}
}
