













































































































































































































































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import WSwitch from '@/components/WSwitch.vue';
import WCostCenter from '@/components/WCostCenter.vue';
import {Component, Watch} from 'vue-property-decorator';
import Base from '../../Base';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators';
import BookingService from '@/service/BookingService';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import {Booking, NationalNumber, Participant, ScheduleType} from '@/models/api';
import AuthenticationService from '@/service/AuthenticationService';
import BookingParticipants from './Participants.vue';
import BookingDateModal from './DateModal.vue';
import HistoricalService from '@/service/HistoricalService';
import {BookingDialInForm} from '@/models/BookingForm';
import PlaybackService from '@/service/PlaybackService';
import {Playback} from '@/models/Playback';
import PlaybackInputGroup from './PlaybackInputGroup.vue';
import {PinCheck} from '@/models/Booking';
import {getSelectLanguages} from "@/utils/languages";

@Component({
	components: {
		HeaderLayout,
		WSwitch,
		WCostCenter,
		WHeaderBtn,
		BookingParticipants,
		BookingDateModal,
		PlaybackInputGroup
	}
})
export default class BookingOnePage extends Base {
	form = BookingService.newBookingForm(this.user);
	booking : Booking | undefined;
	currentTime = new Date().getTime();
	dialOutSwitch = true;
	dialInSwitch = true;

	languages = getSelectLanguages(this)
	ScheduleType = ScheduleType;
	recurrenceDates: string[] = [];

	nationalNumber: NationalNumber | null = null;

	resetIsSeries = false;
	resetFormSchedule = {
		periodicity: 'DAILY',
		periodicityStr: 'DAILY',
		ordinalDayOfMonth: 1,
		end: new Date(Date.now() + 1000 * 60 * 60 * 24 * 2),
		allowedWeekdays: [false, false, false, false, false, true, true]
	};

	playbacks: Playback[] = [];

	resetPin: BookingDialInForm = {
		moderatorAccessCode: '',
		participantAccessCode: ''
	};

	@Validations()
	validations = {
		form: {
			date: {
				topic: {}
			},
			dialIn: {
				moderatorAccessCode: {
					sixIntegers: this.pinValidator,
					unique: this.uniqueValidator,
					required
				},
				participantAccessCode: {
					sixIntegers: this.pinValidator,
					unique: this.uniqueValidator,
					required
				}
			},
			options: {}
		}
	};

	get editMode(): boolean {
		return !!this.$route.query.id;
	}

	get isReccurance(): boolean {
		return BookingService.isRecurrence(this.form.date.schedule.type);
	}

	get selectedTime(): string {
		const date = this.form.date.schedule.start;
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		return `${hours}:${minutes}`;
	}

	set selectedTime(newTime: string) {
		const hours = +newTime.split(':')[0];
		const minutes = +newTime.split(':')[1];
		const date = this.form.date.schedule.start;
		if (!isNaN(hours)) date.setHours(hours);
		if (!isNaN(minutes)) date.setMinutes(minutes);
		date.setSeconds(0);
	}

	get selectedDate(): string {
		const date = this.form.date.schedule.start;
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		return `${date.getFullYear()}-${month}-${day}`;
	}

	set selectedDate(date: string) {
		const year = +date.split('-')[0];
		const month = +date.split('-')[1];
		const day = +date.split('-')[2];
		const beginDate = this.form.date.schedule.start;
		if (!isNaN(year)) beginDate.setFullYear(year);
		if (!isNaN(month)) beginDate.setMonth(month - 1);
		if (!isNaN(day)) beginDate.setDate(day);
	}

	get selectedPlayback(): Playback | undefined {
		return this.playbacks.find((p) => p.id === this.form.date.playbackId);
	}

	@Watch('form.date.schedule.isSeries')
	isSeriesChanged(value: boolean): void {
		if (value && !this.baseLoading) {
			this.openModal();
		}
	}

	@Watch('form.date.type')
	typeChanged(newValue: 'LIVE' | 'PLAYBACK'): void {
		if (newValue === 'LIVE') {
			this.form.dialOut.participants.push(
				BookingService.createDefaultUser(this.user)
			);
		}
		if (newValue === 'PLAYBACK') {
			this.form.options.schooling = true;
			const index = this.form.dialOut.participants.findIndex(
				(p) => p.number === 0
			);
			this.form.dialOut.participants.splice(index, 1);
			this.form.date.schedule.type = ScheduleType.ONETIME;
		}
	}

	created(): void {
		if (this.$route.query.id) {
			this.baseLoading = true;
			const id = this.$route.query.id as string;
			BookingService.getBooking(id)
				.then(booking => {
					this.booking = booking;
					this.form = BookingService.bookingToBookingForm(booking, this.user);
					this.dialInSwitch = booking.dialIn;
					this.dialOutSwitch = booking.dialOut;
					this.resetPin = {
						moderatorAccessCode: this.form.dialIn.moderatorAccessCode,
						participantAccessCode: this.form.dialIn.participantAccessCode
					};
					if (this.editMode && this.form.date.schedule.isSeries) {
						this.$bvModal.msgBoxOk(
							this.t('modals.seriesChangesForms'),
							{
								centered: true,
								title: this.t('common.notice'),
								okTitle: this.t('common.confirm')
							}
						);
					}
				})
				.catch(this.showNetworkError)
				.finally(() => (this.baseLoading = false));
		}
		if (this.$route.query.copyId) {
			const id = this.$route.query.copyId as string;
			if (id.includes('conf')) {
				this.copyBooking(id);
			} else {
				this.copyHistorical(id);
			}
		}
	}

	mounted(): void {
		if (this.user) {
			this.form.date.costCenter = this.user.costCenter || '';
			this.form = BookingService.newBookingForm(this.user);
			BookingService.getNationalNumbers()
				.then((numbers) => {
					this.nationalNumber = numbers.filter((num) => num.active)[0];
				})
				.catch(this.showNetworkError);
		}
		PlaybackService.getPlaybacks().then(
			(playbacks) => (this.playbacks = playbacks)
		);
	}

	private copyBooking(id: string) {
		BookingService.getBooking(id)
			.then((booking) => {
				if (booking.schedule) {
					const date = new Date();
					date.setHours((date.getHours() + 2) % 24);
					booking.schedule.start = date.getTime();
					booking.schedule.type = ScheduleType.IMMEDIATE;
				}
				this.form = BookingService.bookingToBookingForm(booking, this.user);
				this.dialInSwitch = booking.dialIn;
				this.dialOutSwitch = booking.dialOut;
				return BookingService.testBooking(booking);
			})
			.then((valid) => {
				if (!valid.pinOK) {
					console.log('updating pin');
					this.form.dialIn.moderatorAccessCode = BookingService.generatePin();
					this.form.dialIn.participantAccessCode = BookingService.generatePin();
				}
			});
	}

	private copyHistorical(id: string) {
		HistoricalService.getHistorical(id)
			.then((historical) =>
				Promise.all([
					HistoricalService.getHistoricalParticipants(id),
					BookingService.getBooking(historical.bookingId)
				])
			)
			.then((res) => {
				const participants = res[0].sort((a, b) =>
					a.beginDate < b.beginDate ? -1 : 1
				);
				const booking = res[1];
				const phones: string[] = [];
				let newParticipants: Participant[] = [];
				participants.forEach((p) => {
					if (p.type === 'DIALOUT' && !phones.includes(p.phone)) {
						const idxExists = booking.participants?.find(
							(oldParticipant) =>
								oldParticipant.phone === p.phone.split(' ').join('')
						);
						newParticipants.push({
							name: p.name,
							phone: p.phone,
							moderator: p.role === 'MODERATOR',
							email: p.email,
							idx: idxExists ? idxExists.idx : undefined
						});
						phones.push(p.phone);
					}
				});

				booking.participants = newParticipants.sort((a, b) => {
					if (a.idx === undefined && b.idx !== undefined) return 1;
					if (a.idx !== undefined && b.idx === undefined) return -1;
					if (a.idx !== undefined && b.idx !== undefined) {
						return a.idx < b.idx ? -1 : 1;
					}
					return 0;
				});

				if (booking.schedule) {
					const date = new Date();
					date.setHours((date.getHours() + 2) % 24);
					booking.schedule.start = date.getTime();
					booking.schedule.type = ScheduleType.IMMEDIATE;
				}
				this.form = BookingService.bookingToBookingForm(booking, this.user);
				this.dialInSwitch = booking.dialIn;
				this.dialOutSwitch = booking.dialOut;
				this.form.date.language =
					booking.language?.toUpperCase() ||
					this.form.date.language.toUpperCase();
			})
			.catch(this.showNetworkError);
	}

	get preventSubmit(): boolean {
		if (!this.booking || !this.booking.schedule) return false;
		return (this.booking.schedule.start - this.currentTime) < 120000;
	}

	async submit(): Promise<void> {
		this.$v.form.$touch();
		this.currentTime = new Date().getTime();
		if (this.preventSubmit) {
			this.toast(
				this.t('conference.noChangePossible'),
				'danger'
			);
			return;
		}
		if (!this.dialOutSwitch && !this.dialInSwitch) {
			this.toast(
				this.t('conference.selectDialInOrCallParticipants'),
				'danger'
			);
			return;
		}
		if (this.form.date.type === 'PLAYBACK' && !this.form.date.playbackId) {
			this.toast(this.t('conference.pleaseSelectPlayback'), 'danger');
			return;
		}
		if (!this.$v.form.$invalid) {
			const booking: Booking = BookingService.bookingFormToBooking(
				this.form,
				this.dialOutSwitch,
				this.dialInSwitch
			);
			if (!this.editMode) {
				BookingService.testBooking(booking).then((v) => {
					if (v.pinOK) {
						booking.participants?.forEach((p, i) => (p.idx = i));
						return BookingService.createBooking(booking)
							.then((res) =>
								this.redirect(
									res[0],
									booking.schedule?.type === ScheduleType.IMMEDIATE
								)
							)
							.catch(this.handleServerError);
					} else {
						this.handlePinInvalidError(v);
					}
				});
			} else {
				if (this.$route.query.id) {
					booking.id = this.$route.query.id as string;

					const resetModPin = this.resetPin.moderatorAccessCode;
					const bookingModPin = booking.moderatorAccessCode;
					const resetPartPin = this.resetPin.participantAccessCode;
					const bookingPartPin = booking.participantAccessCode;

					if (
						resetModPin === bookingModPin &&
						resetPartPin === bookingPartPin
					) {
						this.updateBooking(booking);
					} else {
						let tempPartPin!: string;
						let tempModPin!: string;
						if (resetPartPin === bookingPartPin) {
							// change part pin for check
							tempPartPin = BookingService.generatePin();
						} else if (resetModPin === bookingModPin) {
							// chagne mod pin for check
							tempModPin = BookingService.generatePin();
						}

						const tempBooking = {
							...booking,
							participantAccessCode:
								tempPartPin || booking.participantAccessCode,
							moderatorAccessCode: tempModPin || booking.moderatorAccessCode
						};

						const check = await BookingService.testBooking(tempBooking).catch(
							this.handleServerError
						);
						if (check) {
							if (check.pinOK) {
								return this.updateBooking(booking);
							} else {
								this.handlePinInvalidError(check);
							}
						}
					}
				}
			}
		}
	}

	private handleServerError(error: any): void {
		const msg = this.errorMessage(error);
		if (
			msg.includes(
				'Der Moderator-Zugangscode ist zu diesem Zeitpunkt nicht möglich.'
			)
		) {
			this.toast(
				this.t('conference.pinErrors.modCodeExists'),
				'danger'
			);
		} else if (
			msg.includes(
				'Der Teilnehmer-Zugangscode ist zu diesem Zeitpunkt nicht möglich.'
			)
		) {
			this.toast(
				this.t('conference.pinErrors.partCodeExists'),
				'danger'
			);
		} else {
			this.showNetworkError(error);
		}
		this.$v.form.dialIn?.$reset();
	}

	private handlePinInvalidError(v: PinCheck): any {
		if (v.errorWithModeratorPin) {
			this.toast(
				this.t('conference.pinErrors.modCodeExists'),
				'danger'
			);
		}
		if (v.errorWithParticipantPin) {
			this.toast(
				this.t('conference.pinErrors.partCodeExists'),
				'danger'
			);
		}
		this.$v.form.dialIn?.$reset();
	}

	private updateBooking(booking: Booking): void {
		BookingService.updateBooking(booking)
			.then((ids) => {
				if (booking.id) this.redirect(ids[0], booking.schedule?.type === ScheduleType.IMMEDIATE);
        this.updatePreviousRoute();
			})
			.catch(this.showNetworkError);
	}

	redirect(booking: string, running?: boolean): void {
		this.$router.push(
			`/conference/${running ? 'running' : 'scheduled'}/${booking}${
				this.editMode ? '' : '?openReg=true'
			}`
		);
	}

	generate(user: string): void {
		const generatedPin = BookingService.generatePin();
		user === 'mod'
			? (this.form.dialIn.moderatorAccessCode = generatedPin)
			: (this.form.dialIn.participantAccessCode = generatedPin);
	}

	reset(user: string): void {
		user === 'mod'
			? (this.form.dialIn.moderatorAccessCode = '')
			: (this.form.dialIn.participantAccessCode = '');
	}

	uniqueValidator(): boolean {
		return (
			this.form.dialIn.moderatorAccessCode !==
			this.form.dialIn.participantAccessCode
		);
	}

  updatePreviousRoute() {
    // If previous route was directing to confDetails, redirect to Dashboard instead
    let route = sessionStorage.getItem('previousRoute');
    if (route && route.includes('conf:')) sessionStorage.setItem('previousRoute', '/');
  }

	pinValidator(value: string): boolean {
		return (value.length === 6 && /^\d+$/.test(value)) || value === '';
	}

	validateState(name: string): boolean | null {
		const validate: any = this.$v.form.dialIn
			? this.$v.form.dialIn[name]
			: this.$v.form[name];
		return validate && validate.$dirty ? !validate.$error : null;
	}

	openModal(): void {
		this.$bvModal.show('seriesModal');
		this.resetFormSchedule = {
			periodicity: this.form.date.schedule.periodicity,
			periodicityStr: this.form.date.schedule.periodicityStr,
			ordinalDayOfMonth: this.form.date.schedule.ordinalDayOfMonth,
			end: this.form.date.schedule.end,
			allowedWeekdays: [...this.form.date.schedule.allowedWeekdays]
		};
		if (!this.form.date.schedule.isSeries)
			this.form.date.schedule.isSeries = true;
	}

	handleSeriesOk(): void {
		if (!this.form.date.schedule.isSeries)
			this.form.date.schedule.isSeries = true;
		this.resetIsSeries = true;
	}

	handleSeriesEnter(): void {
		this.handleSeriesOk();
		this.$bvModal.hide('seriesModal');
	}

	handleSeriesCancel(): void {
		this.form.date.schedule.isSeries = this.resetIsSeries;
		this.form.date.schedule = {
			...this.form.date.schedule,
			periodicity: this.resetFormSchedule.periodicity as
				| 'DAILY'
				| 'MONTHLY'
				| 'WEEKLY',
			periodicityStr: this.resetFormSchedule.periodicityStr,
			ordinalDayOfMonth: this.resetFormSchedule.ordinalDayOfMonth,
			end: this.resetFormSchedule.end,
			allowedWeekdays: [...this.resetFormSchedule.allowedWeekdays]
		};
	}

	saveCostCenters(): void {
		const account = {
			...this.user,
			costCenter: this.form.date.costCenter,
			customerId: this.user.customerShortDTO?.id
		};
		AuthenticationService.updateMyAccount(account).then().catch();
	}

	openNationalNumbers(): void {
		this.$root.$emit('bv::show::modal', 'nationalNumbers');
	}
}
