








































































































































































































































































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import {Component, Watch} from 'vue-property-decorator';
import Base from '../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import WPagination from '@/components/WPagination.vue';
import {ListOptions} from '@/models/Booking';
import {Survey, SurveyApiObject, SurveyStatistic} from '@/models/Survey';
import SurveyService from '@/service/SurveyService';
import BrandingService from '@/service/BrandingService';
import BlacklistModal from '@/components/modals/BlocklistModal.vue';
import RegistrationService from "@/service/RegistrationService";

@Component({
  components: {
    HeaderLayout,
    WHeaderBtn,
    WListOptions,
    WPagination,
    BlacklistModal
  }
})
export default class SurveyOverview extends Base {
  surveys: Survey[] = [];

  tableOptions: ListOptions = {
    entryCount: 10,
    searchTerm: '',
    sortBy: 'dateCreated',
    sortDirection: 'DESC'
  };

  totalRows = 0;
  currentPage = 1;

  selectedSurvey: any | null = null;

  totalStatistics: SurveyStatistic = {
    activeSurveys: 0,
    pastSurveys: 0,
    activeUser: 0,
    brandings: 0
  };

  filteredStatistics: SurveyStatistic = {
    activeSurveys: 0,
    pastSurveys: 0,
    activeUser: 0,
    brandings: 0
  };

  formMode: 'active' | 'past' = 'active';

  get url(): string {
    return process.env.VUE_APP_SURVEY_URL ?? '';
  }

  @Watch('formMode')
  getSurveys(): void {
    if (this.formMode === 'active') this.getActiveSurveys();
    else this.getPastSurveys();
  }

  @Watch('currentPage')
  currentPageChanged(): void {
    this.getSurveys();
  }

  @Watch('tableOptions.searchTerm')
  search(): void {
    this.getSurveys();
  }

  @Watch('tableOptions.entryCount')
  countChanged(): void {
    this.getSurveys();
  }

  sort(): void {
    if (this.tableOptions.sortBy && this.tableOptions.sortDirection) {
      localStorage.setItem('surveys.sortBy', this.tableOptions.sortBy);
      localStorage.setItem(
          'surveys.sortDirection',
          this.tableOptions.sortDirection
      );
    }
    this.getSurveys();
  }

  mounted(): void {
    const sortBy = localStorage.getItem('surveys.sortBy');
    const sortDirection = localStorage.getItem('surveys.sortDirection');
    if (sortBy && sortDirection) {
      this.tableOptions.sortBy = sortBy;
      this.tableOptions.sortDirection = sortDirection as 'ASC' | 'DESC';
    }

    const {mode, formUrl} = this.$route.query;
    if (mode) {
      this.formMode = mode as any;
    }
    this.getPastSurveys();
    this.getActiveSurveys();
    this.getStatistics();

    if (formUrl) {
      this.selectedSurvey = {url: ('/' + formUrl) as string};
      setTimeout(() => {
        this.$bvModal.show('surveyInfoModal');
        this.$router.replace({query: {}});
      }, 1000);
    }
  }

  getActiveSurveys(): void {
    this.formMode = 'active'
    SurveyService.getActiveSurveys(
        this.currentPage - 1,
        this.tableOptions.entryCount,
        this.tableOptions.sortBy || 'dateCreated',
        this.tableOptions.sortDirection || 'DESC',
        this.tableOptions.searchTerm
    ).then((surveys) => {
      this.filteredStatistics.activeSurveys = surveys.totalSize;
      this.setSurvey(surveys, 'active');
    });
  }

  getPastSurveys(): void {
    this.formMode = 'past';
    SurveyService.getPastSurveys(
        this.currentPage - 1,
        this.tableOptions.entryCount,
        this.tableOptions.sortBy || 'dateCreated',
        this.tableOptions.sortDirection || 'DESC',
        this.tableOptions.searchTerm
    ).then(surveys => {
      this.filteredStatistics.pastSurveys = surveys.totalSize;
      this.setSurvey(surveys, 'past');
    });
  }

  private setSurvey(surveys: SurveyApiObject, mode?: 'active' | 'past'): void {
    if (mode && this.formMode && mode !== this.formMode) return;

    if (surveys && surveys.content) {
      this.surveys = surveys.content;
      this.totalRows = surveys.totalSize;
    } else {
      this.surveys = [];
      this.totalRows = 0;
    }
  }

  getStatistics(): void {
    SurveyService.getSurveyStatistics()
        .then((statistics) => {
          this.totalStatistics = statistics;
          return BrandingService.getBrandings();
        })
        .then(
            (brandings) =>
                (this.totalStatistics = {
                  ...this.totalStatistics,
                  brandings: brandings.length
                })
        )
        .catch(this.showNetworkError);
  }

  deleteSurvey(id: number): void {
    // Detect if survey has participants to show different msg when deleting a survey
    const hasResponses = this.checkSurveyForResponses(id);

    if (id) {
      this.$bvModal
          .msgBoxConfirm(
              hasResponses
                  ? this.t('modals.deleteSurvey.descWithParticipants')
                  : this.t('modals.deleteSurvey.descNoParticipants'),
              {
                okVariant: 'danger',
                okTitle: this.t('modals.deleteSurvey.ok'),
                cancelTitle: this.t('common.cancel'),
                centered: true,
                title: this.t('modals.deleteSurvey.title')
              }
          )
          .then((res) => {
            if (res) {
              SurveyService.deleteSurvey(id).then(() => {
                this.toast(this.t('modals.deleteSurvey.deleted'), 'success');
                this.getSurveys();
                this.getStatistics();
              });
            }
          })
          .catch(this.showNetworkError);
    }
  }

  deleteSurveyResponses(id: number): void {
    // Detect if survey has participants to show different msg when deleting a survey
    const hasResponses = this.checkSurveyForResponses(id);

    if (hasResponses) {
      this.$bvModal
          .msgBoxConfirm(this.t('modals.deleteSurveyResponses.description'), {
            okVariant: 'danger',
            okTitle: this.t('modals.deleteSurveyResponses.ok'),
            cancelTitle: this.t('common.cancel'),
            centered: true,
            title: this.t('modals.deleteSurveyResponses.title')
          })
          .then((res) => {
            if (res) {
              SurveyService.deleteAllResponses(id)
                  .then(() => {
                    this.toast(
                        this.t('modals.deleteSurveyResponses.deleted'),
                        'success'
                    );
                    this.getSurveys();
                    this.getStatistics();
                  })
                  .catch(this.showNetworkError);
            }
          });
    } else {
      this.toast(this.t('modals.deleteSurveyResponses.noResponses'), 'danger');
    }
  }

  private checkSurveyForResponses(id: number) {
    let selectedSurvey = this.surveys.find((s) => s.id === id);
    let hasParticipants = false;
    if (selectedSurvey && selectedSurvey.countRegisteredUser)
      hasParticipants = selectedSurvey.countRegisteredUser > 0;
    return hasParticipants;
  }

  openLink(urlPath: string): void {
    window.open(this.url + urlPath, '_blank');
  }

  urlPath(urlPath: string): string {
    return process.env.VUE_APP_SURVEY_URL + urlPath;
  }

  openInfoModal(survey: Survey): void {
    this.selectedSurvey = survey;
    this.$bvModal.show('surveyInfoModal');
  }

  toggleLock(survey: Survey): void {
    if (survey.id) {
      if (survey.active) {
        SurveyService.deactivateForm(survey.id).then(
            () => (survey.active = false)
        );
      } else {
        SurveyService.activateForm(survey.id).then(
            () => (survey.active = true)
        );
      }
    }
  }

  duplicate(survey: Survey): void {
    if (survey.id) {
      SurveyService.duplicateSurvey(survey.id)
          .then((newSurvey) => {
            if (newSurvey.id) {
              this.toast(this.t('forms.duplicated'), 'success');
              this.$router.push({
                path: `/forms/survey/${newSurvey.id}/edit`,
                query: {id: newSurvey.id.toString()}
              });
            }
          })
          .catch(this.showNetworkError);
    }
  }

  async enableBlacklist(survey: Survey): Promise<void> {
    if (survey.id) {
      survey.ignoreBlacklist = false;
      await SurveyService.updateGeneral(survey.id, survey);
      this.toast(this.t('forms.blacklistEnabled'), 'success');
    }
  }

  async disableBlacklist(survey: Survey): Promise<void> {
    if (survey.id) {
      survey.ignoreBlacklist = true;
      await SurveyService.updateGeneral(survey.id, survey);
      this.toast(this.t('forms.blacklistDisabled'), 'success');
    }
  }

  deleteAllPastForms(): void {
    this.$bvModal
        .msgBoxConfirm(this.t('modals.deleteAllOldRegModal.description'), {
          title: this.t('modals.deleteAllOldRegModal.title'),
          centered: true,
          okVariant: 'danger',
          okTitle: this.t('modals.deleteAllOldRegModal.ok'),
          cancelTitle: this.t('common.cancel')
        })
        .then((res) => {
          if (res) {
            SurveyService.deleteAllPastSurveys().then(() => {
                  this.toast(
                      this.t('modals.deleteAllOldRegModal.deleted'),
                      'success'
                  );
                  this.totalStatistics.pastSurveys = 0;
                  this.getPastSurveys();
                  this.getStatistics();
                }
            )
                .catch(()=>{
                  this.toast(this.t('common.serverError'), 'danger')
                })
          }
        });
  }

}
