import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from '@/i18n/en';
import de from '@/i18n/de';

const messages = {
	en: { ...en },
	de: { ...de }
};

Vue.use(VueI18n);

// Create VueI18n instance with options
export default new VueI18n({
	locale: localStorage.getItem('language') || navigator.languages[0], // set locale
	fallbackLocale: 'de',
	messages // set locale messages
});
