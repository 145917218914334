import { render, staticRenderFns } from "./AdminSection.vue?vue&type=template&id=5f04434b&scoped=true&"
import script from "./AdminSection.vue?vue&type=script&lang=ts&"
export * from "./AdminSection.vue?vue&type=script&lang=ts&"
import style0 from "./AdminSection.vue?vue&type=style&index=0&id=5f04434b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f04434b",
  null
  
)

export default component.exports