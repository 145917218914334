








































import { DropdownItem, NavItem } from '@/models/Nav';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import WDropdownItem from '@/components/applayout/WDropdownItem.vue';

@Component({
	components: { WDropdownItem },
	filters: {}
})
export default class WNavDropdown extends Vue {
	@Prop() item!: NavItem;
	@Prop() right!: boolean;

	isIcon(str: string): boolean {
		return this.item.icon?.name === str;
	}

	navItemIsActive(navItem: NavItem, route: any): boolean {
		const isHome = route.name === 'Home' && navItem.id === 'Conference';
		return isHome || route.name?.includes(navItem?.id || '') || false;
	}
	navDropdownItemIsActive(dropdownItem: DropdownItem, route: any): boolean {
		const str = (dropdownItem?.to as any)?.name || dropdownItem?.to || '';
		return route.name?.includes(str) || false;
	}
}
