import ArrowRightCircleIcon from '@/assets/icons/arrow-right-circle.svg';
import SortIcon from '@/assets/icons/sort.svg';
import SortUpIcon from '@/assets/icons/sort-up.svg';
import SortDownIcon from '@/assets/icons/sort-down.svg';
import CustomerServiceIcon from '@/assets/icons/customer-service.svg';
import DiamondIcon from '@/assets/icons/diamond.svg';
import InfoIcon from '@/assets/icons/info.svg';
import LiveCircleIcon from '@/assets/icons/live-circle.svg';
import PlaybackCircleIcon from '@/assets/icons/playback-circle.svg';
import UserIcon from '@/assets/icons/user.svg';
import UsersIcon from '@/assets/icons/users.svg';
import UserCircleIcon from '@/assets/icons/user-circle.svg';
import UserTimesIcon from '@/assets/icons/user-times.svg';
import MicrophoneIcon from '@/assets/icons/microphone.svg';
import CallInIcon from '@/assets/icons/call-in.svg';
import CallOutIcon from '@/assets/icons/call-out.svg';
import ThreeDotsIcon from '@/assets/icons/three-dots.svg';
import PointerIcon from '@/assets/icons/pointer.svg';
import HeadsetIcon from '@/assets/icons/headset.svg';
import GraduateHatIcon from '@/assets/icons/graduate-hat.svg';
import ScreenPlayIcon from '@/assets/icons/screen-play.svg';
import LiveCirclesIcon from '@/assets/icons/live-circles.svg';
import ChatBubblesIcon from '@/assets/icons/chat-bubbles.svg';
import FingerprintIcon from '@/assets/icons/fingerprint.svg';
import ShieldLockIcon from '@/assets/icons/shield-lock.svg';
import CalendarNumberedIcon from '@/assets/icons/calendar-numbered-icon.svg';
import GlobeIcon from '@/assets/icons/globe.svg';
import EnvelopeIcon from '@/assets/icons/envelope.svg';
import PadlockLoginIcon from '@/assets/icons/padlock-login.svg';
import PadlockLockedIcon from '@/assets/icons/padlock-locked.svg';
import PadlockUnlockedIcon from '@/assets/icons/padlock-unlocked.svg';
import ClipboardIcon from '@/assets/icons/clipboard.svg';
import PlusIcon from '@/assets/icons/plus.svg';
import ChevronLeftIcon from '@/assets/icons/chevron-left.svg';
import ChevronRightIcon from '@/assets/icons/chevron-right.svg';
import ChevronUpIcon from '@/assets/icons/chevron-up.svg';
import ChevronDownIcon from '@/assets/icons/chevron-down.svg';
import ChevronDoubleLeftIcon from '@/assets/icons/chevron-double-left.svg';
import ChevronDoubleRightIcon from '@/assets/icons/chevron-double-right.svg';
import ChevronDoubleUpIcon from '@/assets/icons/chevron-double-up.svg';
import ChevronDoubleDownIcon from '@/assets/icons/chevron-double-down.svg';
import CalendarIcon from '@/assets/icons/calendar.svg';
import CopyIcon from '@/assets/icons/copy.svg'
import PresentationIcon from '@/assets/icons/presentation.svg';
import CheckIcon from '@/assets/icons/check.svg';
import CheckBoldIcon from '@/assets/icons/check-bold.svg';
import CheckPhoneIcon from '@/assets/icons/check-phone.svg';
import BellIcon from '@/assets/icons/bell.svg';
import CoffeeIcon from '@/assets/icons/coffee.svg';
import ClockIcon from '@/assets/icons/clock.svg';
import BuildingIcon from '@/assets/icons/building.svg';
import PhoneIcon from '@/assets/icons/phone.svg';
import VolumeUpIcon from '@/assets/icons/volume-up.svg';
import PencilIcon from '@/assets/icons/pencil.svg';
import PollIcon from '@/assets/icons/poll.svg';
import TimesIcon from '@/assets/icons/times.svg';
import TimesCircleIcon from '@/assets/icons/times-circle.svg';
import SearchIcon from '@/assets/icons/search.svg';
import KeyIcon from '@/assets/icons/key.svg';
import ThListIcon from '@/assets/icons/th-list.svg';
import ThLargeIcon from '@/assets/icons/th-large.svg';
import TrashIcon from '@/assets/icons/trash.svg';
import DownloadIcon from '@/assets/icons/download.svg';
import UploadIcon from '@/assets/icons/upload.svg';
import PaperclipIcon from '@/assets/icons/paperclip.svg';
import ExternalLinkIcon from '@/assets/icons/external-link.svg';
import ImageIcon from '@/assets/icons/image.svg';
import FilePdfIcon from '@/assets/icons/file-pdf.svg';
import FilePptxIcon from '@/assets/icons/file-pptx.svg';
import AddressBookIcon from '@/assets/icons/address-book.svg';
import PlayIcon from '@/assets/icons/play.svg';
import CircleIcon from '@/assets/icons/circle.svg';
import BarsIcon from '@/assets/icons/bars.svg';
import RefreshIcon from '@/assets/icons/refresh.svg';
import LCircleIcon from '@/assets/icons/l-circle.svg';
import PCircleIcon from '@/assets/icons/p-circle.svg';
import VoicemailIcon from '@/assets/icons/voicemail.svg';
import WCircleIcon from '@/assets/icons/w-circle.svg';
import DCircleIcon from '@/assets/icons/d-circle.svg';
import EyeIcon from '@/assets/icons/eye.svg';
import RepeatIcon from '@/assets/icons/repeat.svg';
import TemplateIcon from '@/assets/icons/template.svg';
import CheckShieldFilled from '@/assets/icons/check-shield-filled.svg';
import CheckShield from '@/assets/icons/check-shield.svg';
import Headphones from '@/assets/icons/headphones.svg';
import GutBeraten from '@/assets/icons/gut-beraten.svg';
import GutBeratenOutline from '@/assets/icons/gut-beraten-outline.svg';
import ConfPlanned from '@/assets/icons/conf-planned.svg';
import ConfNotplanned from '@/assets/icons/conf-notplanned.svg';
import CameraIcon from '@/assets/icons/camera.svg';
import CogIcon from '@/assets/icons/cog.svg';
import ConversationIcon from '@/assets/icons/conversation.svg';
import HandIcon from '@/assets/icons/hand.svg';
import FingerUpIcon from '@/assets/icons/finger-up.svg';
import QuestionOutlineIcon from '@/assets/icons/question-outline.svg';
import SortByIcon from '@/assets/icons/sort-by.svg';
import StarIcon from '@/assets/icons/star.svg'
import Link from '@/assets/icons/link.svg'
import LinkOff from '@/assets/icons/link-off.svg'
import FacebookIcon from '@/assets/icons/facebook.svg'
import WhatsappIcon from '@/assets/icons/whatsapp.svg'
import TwitterIcon from '@/assets/icons/twitter.svg'
import TelegramIcon from '@/assets/icons/telegram.svg'
import LinkedinIcon from '@/assets/icons/linkedin.svg'
import XingIcon from '@/assets/icons/xing.svg'

import ExclamationCircleIcon from '@/assets/icons/exclamation-circle.svg'
import ExclamationIcon from '@/assets/icons/exclamation.svg'
import HistoryIcon from '@/assets/icons/history.svg'
import LogoutIcon from '@/assets/icons/sign-out.svg'

import W3Globe from '@/assets/pictures/w3-globe.svg';
import LogoIcon from '@/assets/logo.svg';
import LogoThemedIcon from '@/assets/logo-themed.svg';
import LogoLongIcon from '@/assets/logo-long.svg';
import JanitosLogo from '@/assets/icons/references/janitos.svg';
import SparkasseLogo from '@/assets/icons/references/sparkasse.svg';
import RuvLogo from '@/assets/icons/references/ruv.svg'

export const icons = [
	{ icon: ArrowRightCircleIcon, name: 'arrow-right-circle-icon' },
	{ icon: SortIcon, name: 'sort-icon' },
	{ icon: SortUpIcon, name: 'sort-up-icon' },
	{ icon: SortDownIcon, name: 'sort-down-icon' },
	{ icon: CustomerServiceIcon, name: 'customer-service-icon' },
	{ icon: DiamondIcon, name: 'diamond-icon' },
	{ icon: InfoIcon, name: 'info-icon' },
	{ icon: LiveCircleIcon, name: 'live-circle-icon' },
	{ icon: PlaybackCircleIcon, name: 'playback-circle-icon' },
	{ icon: UserIcon, name: 'user-icon' },
	{ icon: UserCircleIcon, name: 'user-circle-icon' },
	{ icon: UsersIcon, name: 'users-icon' },
	{ icon: UserTimesIcon, name: 'user-times-icon' },
	{ icon: MicrophoneIcon, name: 'microphone-icon' },
	{ icon: CallInIcon, name: 'call-in-icon' },
	{ icon: CallOutIcon, name: 'call-out-icon' },
	{ icon: ThreeDotsIcon, name: 'three-dots-icon' },
	{ icon: PointerIcon, name: 'pointer-icon' },
	{ icon: HeadsetIcon, name: 'headset-icon' },
	{ icon: GraduateHatIcon, name: 'graduate-hat-icon' },
	{ icon: ScreenPlayIcon, name: 'screen-play-icon' },
	{ icon: LiveCirclesIcon, name: 'live-circles-icon' },
	{ icon: ChatBubblesIcon, name: 'chat-bubbles-icon' },
	{ icon: FingerprintIcon, name: 'fingerprint-icon' },
	{ icon: ShieldLockIcon, name: 'shield-lock-icon' },
	{ icon: CalendarNumberedIcon, name: 'calendar-numbered-icon' },
	{ icon: GlobeIcon, name: 'globe-icon' },
	{ icon: EnvelopeIcon, name: 'envelope-icon' },
	{ icon: PadlockLoginIcon, name: 'padlock-login-icon' },
	{ icon: PadlockLockedIcon, name: 'padlock-locked-icon' },
	{ icon: PadlockUnlockedIcon, name: 'padlock-unlocked-icon' },
	{ icon: ClipboardIcon, name: 'clipboard-icon' },
	{ icon: PlusIcon, name: 'plus-icon' },
	{ icon: ChevronLeftIcon, name: 'chevron-left-icon' },
	{ icon: ChevronRightIcon, name: 'chevron-right-icon' },
	{ icon: ChevronUpIcon, name: 'chevron-up-icon' },
	{ icon: ChevronDownIcon, name: 'chevron-down-icon' },
	{ icon: ChevronDoubleLeftIcon, name: 'chevron-double-left-icon' },
	{ icon: ChevronDoubleRightIcon, name: 'chevron-double-right-icon' },
	{ icon: ChevronDoubleUpIcon, name: 'chevron-double-up-icon' },
	{ icon: ChevronDoubleDownIcon, name: 'chevron-double-down-icon' },
	{ icon: CalendarIcon, name: 'calendar-icon' },
	{icon: CopyIcon, name: 'copy-icon'},
	{ icon: PresentationIcon, name: 'presentation-icon' },
	{ icon: CheckIcon, name: 'check-icon' },
	{ icon: CheckBoldIcon, name: 'check-bold-icon' },
	{ icon: CheckPhoneIcon, name: 'check-phone-icon' },
	{ icon: BellIcon, name: 'bell-icon' },
	{ icon: CoffeeIcon, name: 'coffee-icon' },
	{ icon: ClockIcon, name: 'clock-icon' },
	{ icon: BuildingIcon, name: 'building-icon' },
	{ icon: PhoneIcon, name: 'phone-icon' },
	{ icon: VolumeUpIcon, name: 'volume-up-icon' },
	{ icon: PencilIcon, name: 'pencil-icon' },
	{ icon: PollIcon, name: 'poll-icon' },
	{ icon: TimesIcon, name: 'times-icon' },
	{ icon: TimesCircleIcon, name: 'times-circle-icon' },
	{ icon: SearchIcon, name: 'search-icon' },
	{ icon: KeyIcon, name: 'key-icon' },
	{ icon: ThListIcon, name: 'th-list-icon' },
	{ icon: ThLargeIcon, name: 'th-large-icon' },
	{ icon: TrashIcon, name: 'trash-icon' },
	{ icon: DownloadIcon, name: 'download-icon' },
	{ icon: UploadIcon, name: 'upload-icon' },
	{ icon: PaperclipIcon, name: 'paperclip-icon' },
	{ icon: ExternalLinkIcon, name: 'external-link-icon' },
	{ icon: ImageIcon, name: 'image-icon' },
	{ icon: FilePdfIcon, name: 'file-pdf-icon' },
	{ icon: FilePptxIcon, name: 'file-pptx-icon' },
	{ icon: AddressBookIcon, name: 'address-book-icon' },
	{ icon: PlayIcon, name: 'play-icon' },
	{ icon: CircleIcon, name: 'circle-icon' },
	{ icon: BarsIcon, name: 'bars-icon' },
	{ icon: RefreshIcon, name: 'refresh-icon' },
	{ icon: PCircleIcon, name: 'p-circle-icon' },
	{ icon: LCircleIcon, name: 'l-circle-icon' },
	{ icon: VoicemailIcon, name: 'voicemail-icon' },
	{ icon: WCircleIcon, name: 'w-circle-icon' },
	{ icon: DCircleIcon, name: 'd-circle-icon' },
	{ icon: EyeIcon, name: 'eye-icon' },
	{ icon: RepeatIcon, name: 'repeat-icon' },
	{ icon: TemplateIcon, name: 'template-icon' },
	{ icon: CheckShieldFilled, name: 'check-shield-filled-icon' },
	{ icon: CheckShield, name: 'check-shield-icon' },
	{ icon: Headphones, name: 'headphones-icon' },
	{ icon: GutBeraten, name: 'gut-beraten-icon' },
	{ icon: GutBeratenOutline, name: 'gut-beraten-outline-icon' },
	{ icon: ConfPlanned, name: 'conf-planned-icon' },
	{ icon: ConfNotplanned, name: 'conf-notplanned-icon' },
	{ icon: CameraIcon, name: 'camera-icon' },
	{ icon: CogIcon, name: 'cog-icon' },
	{ icon: ConversationIcon, name: 'conversation-icon' },
	{ icon: HandIcon, name: 'hand-icon' },
	{ icon: FingerUpIcon, name: 'finger-up-icon' },
	{ icon: QuestionOutlineIcon, name: 'question-outline-icon' },
	{ icon: SortByIcon, name: 'sort-by-icon' },
	{ icon: StarIcon, name: 'star-icon' },
	{ icon: Link, name: 'link-icon' },
	{ icon: LinkOff, name: 'link-off-icon' },
	{icon: FacebookIcon, name: 'facebook-icon'},
	{icon: WhatsappIcon, name: 'whatsapp-icon'},
	{icon: TwitterIcon, name: 'twitter-icon'},
	{icon: TelegramIcon, name: 'telegram-icon'},
	{icon: LinkedinIcon, name: 'linkedin-icon'},
	{icon: XingIcon, name: 'xing-icon'},

	{icon: ExclamationCircleIcon, name: 'exclamation-circle-icon'},
	{icon: ExclamationIcon, name: 'exclamation-icon'},
	{icon: HistoryIcon, name: 'history-icon'},
	{icon: LogoutIcon, name: 'logout-icon'},

	{ icon: W3Globe, name: 'w3-globe' },
	{ icon: LogoIcon, name: 'logo-icon' },
	{ icon: LogoThemedIcon, name: 'logo-themed-icon' },
	{ icon: LogoLongIcon, name: 'logo-long-icon' },
	{ icon: JanitosLogo, name: 'janitos-logo' },
	{ icon: SparkasseLogo, name: 'sparkasse-logo' },
	{ icon: RuvLogo, name: 'ruv-logo' },
];
