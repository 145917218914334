


























import {Vue, Component, Prop} from 'vue-property-decorator';
import WSwitch from "@/components/WSwitch.vue";

@Component({
  components: {WSwitch}
})
export default class WExpandableCard extends Vue {

  @Prop({default: false, type: Boolean}) expandable!: boolean;
  @Prop() title!: string;
  @Prop() link?: string;
  @Prop({default: true, type: Boolean}) defaultExpanded!: boolean;

  open = true;

  mounted() {
    this.open = this.defaultExpanded;
  }

  toggleOpen(): void {
    this.open = !this.open;
  }

  linkClicked() {
    this.$emit("link-clicked");
  }

}
