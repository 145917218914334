import {
	Contact,
	ContactsWrapper,
	GroupContact,
	ValidatedPhoneNumber
} from '@/models/Contact';
import axios from 'axios';
import { debounce, DebouncedFunc } from 'lodash';

const headers = {
	accept: 'application/json',
	'Content-Type': 'application/json'
};

interface ContactParams {
	page: number;
	size: number;
	order: string;
	direction: 'ASC' | 'DESC';
	search?: string;
	groupName?: string;
}

/**
 * API calls for contacts
 * @module service/ContactsService
 */
export default {
	/**
	 * Rest Functions contacts
	 */
	getContacts(
		page: number,
		size: number,
		order: string,
		direction: 'ASC' | 'DESC',
		search?: string,
		groupName?: string
	): Promise<ContactsWrapper> {
		const params: ContactParams = { page, size, order, direction };
		if (search) params['search'] = this.trimSearchTerm(search);
		if (groupName) params['groupName'] = groupName;
		return axios
			.get(`/contact`, { params, headers })
			.then((response) => response.data);
	},

	getContact(id: string): Promise<Contact> {
		return axios.get(`/contact/${id}`).then((response) => response.data);
	},

	updateContact(id: string, contact: Contact): Promise<Contact> {
		return axios
			.put(`/contact/${id}`, contact)
			.then((response) => response.data);
	},

	updateContactGroup(id: string, groupId: string): Promise<Contact> {
		return axios
			.put(`/contact/${id}/groups/${groupId}`, { data: { text: 'lorem' } })
			.then((response) => response.data);
	},

	addContact(contact: GroupContact): Promise<Contact> {
		return axios.post(`/contact`, contact).then((response) => response.data);
	},

	addContacts(contacts: GroupContact[]): Promise<Contact[]> {
		return axios.put(`/contact`, contacts).then((response) => response.data);
	},

	deleteContact(id: string): Promise<void> {
		return axios.delete(`/contact/${id}`).then();
	},

	deleteAllContacts(): Promise<void> {
		return axios.delete(`/contact`).then();
	},
	deleteAllContactsWithoutGroup(): Promise<void> {
		return axios.delete(`/contact/withoutGroup`).then();
	},

	deleteContactGroup(id: string, groupId: string): Promise<Contact> {
		return axios
			.delete(`/contact/${id}/groups/${groupId}`)
			.then((res) => res.data);
	},

	validatePhoneNumbers(
		numbers: string[]
	): Promise<Array<ValidatedPhoneNumber>> {
		return axios
			.post(`/validator/phonenumber`, numbers)
			.then((response) => response.data);
	},

	/**
	 * Functions
	 */
	createValidationDebounce(
		validationFunction: () => void
	): DebouncedFunc<() => void> {
		return debounce(validationFunction, 500);
	},

	noLettersValidator(value: string): boolean {
		const valid = value && value !== '' ? /^[^a-zA-Z]+$/.test(value) : true;
		return valid;
	},

	trimSearchTerm(term: string): string {
		const trimmedTerm = term
			.replace('+', '')
			.replace(/[&/\\#, +()$~%.'":*?<>{}]/g, '')
			.replace('-', '')
			.replace('_', '');
		const isnumber = /^\d+$/.test(trimmedTerm);
		if (isnumber) {
			term = term
				.replace('+', '')
				.replace(/[&/\\#, +()$~%.'":*?<>{}]/g, ' ')
				.replace('-', ' ')
				.replace('_', ' ');
		}
		if (term.startsWith('00')) term = term.substring(2);
		else if (term.startsWith('0')) term = term.substring(1);
		return term;
	}
};
